.hidden {
  height: 100vh;
  overflow: hidden;
}

.break {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

@media only screen and (max-width: 767px) {
  .break {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
}
