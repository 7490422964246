html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h2,
h2 {
  font-size: 2.5rem !important;
}

.h4,
h4 {
  font-size: 2rem !important;
}

.h5,
h5 {
  font-size: 1.75rem !important;
}

p {
  font-size: 1.2rem !important;
}

.subtitle {
  font-size: 1.75rem !important;
}
