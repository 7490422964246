.loader_bg {
  position: fixed;
  z-index: 999999;
  background: #ffffff;
  width: 100vw;
  height: 100vh;
}

.loader_bg.hide {
  pointer-events: none;
  transition: opacity 1s;
  transition-timing-function: ease-out;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.goodbye {
  -webkit-animation: fadeinout 2.5s linear forwards;
  animation: fadeinout 2.5s linear forwards;
  opacity: 0;
}

@keyframes fadeinout {
  50% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn ease-in 1s;
  -webkit-animation: fadeIn ease-in 1s;
  -moz-animation: fadeIn ease-in 1s;
  -o-animation: fadeIn ease-in 1s;
  -ms-animation: fadeIn ease-in 1s;
}

.fade-in.hide {
  pointer-events: none;
  transition: opacity 1s;
  transition-timing-function: ease-out;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
