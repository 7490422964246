.click {
  min-width: 100px;
  width: 100%;
  background-color: Transparent;
  background-repeat: no-repeat;
  text-align: left !important;
  border-color: gray;
  border-left: 2px solid !important;
  border-bottom: none !important;
  border-top: none !important;
  border-right: none !important;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: inherit !important;
  border-radius: 0 !important;
}

.click:focus,
.click:active {
  outline: none;
  box-shadow: none !important;
}

.click:hover {
  border-color: #b08d57 !important;
  background-color: #0f0f0f !important;
  color: #b08d57 !important;
}

.selected {
  border-color: #b08d57 !important;
  color: #b08d57 !important;
}

.header {
  display: flex;
  align-items: center;
}

.header::after {
  margin-top: 5px;
  content: "";
  flex: 1;
  margin-left: 1rem;
  height: 1px;
  background-color: #000;
}

.min-height {
  min-height: 320px !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {
  .click {
    border-color: gray;
    height: 100%;
    border-bottom: 2px solid !important;
    border-left: none !important;
  }
  .buttonContainer {
    display: flex;
    overflow-x: auto;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .buttonContainer::-webkit-scrollbar {
    display: none;
  }
  .header {
    justify-content: center !important;
  }
  .header::after {
    margin-top: 0px;
    content: "";
    flex: 0;
    margin-left: 0;
    height: 0;
    background-color: #000;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .test {
    width: 800px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .test {
    width: 1000px !important;
  }
}
